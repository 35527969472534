.container-wrap-back_button {
  display: flex;
  justify-content: left;
  cursor: pointer;
}

.container-back_button {
  display: flex;
  align-items: center;
  /* width: 200px; */
}

.back_button-icon {
  width: 12px;
  height: 10px;
}

.back_button-name {
  font-size: 16px;
  color: #0E73ED;
  margin-left: 5px;
}
