.item {
    padding: 5px;
    border: 2px solid #656565;
    background-color: #F4F2F6;
    border-radius: 10px;
}

.img {
    border-radius: 50%;
    /* width: 70px; */

    height: 70px;
    margin: auto 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}