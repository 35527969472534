.container-main {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* .container-menu-work {
    display: flex;
} */

.title-screen {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 10px;
    padding: 15px;
    /* text-align: center; */
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    padding-left: 40px;
}
.title-screen svg {
    margin-right: 20px;
    vertical-align: middle;
    font-size: 40px;
}
.title-screen .material-icons {
    margin-right: 20px;
    vertical-align: middle;
    font-size: 40px;
}
.loading-screen{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 9999;
}

/* 
@media (max-width: 1400px) {
    .container-menu-work {
        display: flex;
        flex: 1;
    }
} */