.menu-header {
  padding: 10px;
}

.menu-header-info-welcome {
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}

/* .menu-header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
} */

.menu-header-info {
  /* display: flex;
  flex-direction: column; */
  font-size: 14px;
  text-align: center;
  margin-left: 40px;
}

.menu-header-info-name {
  font-size: 16px;
  margin-top: 0;
  /* font-weight: bold; */
}

.menu-header-info-user {
  margin-bottom: 5px;
  margin-top: 0;
}

.menu-header-account-balance {
  font-size: 16px;
  font-weight: bold;
}

.menu-header-info-close-session  {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  flex: 1;
}

.menu-header-info-bag {
  white-space: nowrap;
}

.menu-sidebar-button {
  align-self: end;
  height: 30px;
  margin-right: -10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 5px;
  padding-bottom: 15px;
}

.sidebar-button-icon {
  font-size: 30px !important;
}

.container-header-logo {
  display: flex;
  margin-bottom: 20px;
}

.header-logo {
  max-width: 50%;
  max-height: 100px;
  margin: auto;
}