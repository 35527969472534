html, body, #root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.material-icons {
  font-family: 'Material Icons' !important;
}
.container-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-title .title{
  font-size: 16px;
  font-weight: 700;
}

.container-title .subtitle{
  font-size: 16px;
  font-weight: 600;
  color: #A7A7A7;
}
#ticket-print {
  width: 240px;
  text-align: center;
}
#ticket-print .title{
  /* font-size: 10px; */
  font-weight: bold;
  margin-bottom: 10px;
}
#ticket-print .text{
  /* font-size: 8px; */
}
