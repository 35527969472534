.container-wrap-menu-bar{
  display: flex;
}

.container-menu-bar {
  flex: 1;
  display: flex;
  cursor: pointer;
  margin: 5px 0;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.container-menu-bar .MuiSvgIcon-root {
  min-width: 24px;
  margin-bottom: 5px;
}
.container-menu-bar-icon {
  margin: 0;
}

.menu-bar-icon {
  width: 20px;
  height: 20px;
}

.container-menu-bar-icon-arrow{
  margin-left: auto;
}

.menu-bar-name {
  font-size: 14px;
}

.container-menu-bar-name {
  align-items: center;
  display: flex;
  padding-left: 5px;
}