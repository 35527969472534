.work-area {
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: auto;
}

.work-sidebar-button {
  position: absolute;
  height: 40px;
  margin-top: 10px;
  margin-left: -10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.sidebar-button-icon{
  font-size: 40px !important;
}