.container-image-icon {
    display: contents;
    align-items: center;
    width: 183px;
}

.image-icon {
    width: 150px;
    height: 154px;
    border-radius: 50%;
}

.container-image-edit-icon {
    /* margin: 0; */
    width: 18px;
}

.image-edit-icon {
    width: 18px;
    height: 20px;
}
.container-complement {
  margin-bottom: 10px;
}
