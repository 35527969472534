.wrapper-pagination{
    align-content: stretch;
    justify-content: flex-end;
    padding-top: 10px;
    display: flex;
    align-content: stretch;
    justify-content: flex-end;
    flex-direction: row;
    justify-content: space-between;
    
}
.no-page{
    padding-bottom: 10px;
}
.Mui-selected{
    background: '#707070';
}