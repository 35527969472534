.container-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-title .commerce{
    font-size: 16px;
    font-weight: 700;
}

.container-title .bag{
    font-size: 16px;
    font-weight: 600;
    color: #A7A7A7;
}

.balance {
    text-align: center; 
    font-size: 16px;
    font-weight: 600;
    color: #A7A7A7; 
}