.iconUser{
  padding: 10px;
  border-radius: 50px;
  /* max-width: 40px; */
  text-align: center;
}

.iconUser span{
  /* font-family: GeoramaBold !important; */
  font-size: 25px;
  color: #FFFFFF;
  font-weight: bold;
}