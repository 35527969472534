/* .container {
    position: relative;
    display: flex;
    justify-content: center;
} */
.login_screen {
    display: contents;
}
.logo {
    width: 300px;
    margin-bottom: 50px;

}

.recover-password {
    cursor: pointer;
    margin-top: 15px;
}
