.item-card {
    border-radius: 10px;
    height: auto;
    background-color: #F4F2F6;
    cursor: pointer;
    padding-bottom: 15px;
}


.active {
    box-shadow: 5px 5px 10px #ED3726;
}


.img-card-item {
    height: 70px;
    max-width: 120px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.label {
    font-size: 35px;
    font-weight: 600;
}
.card-item-name{
    padding: 5px;
}
.card-item-select {
    display: grid;
    justify-content: end;
    padding: 6px 6px 0 0;
    height: 15px;
}

.card-item-commission {
    color: #ED3726 !important;
    width: '15px'
}

.css-fylgjh-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #ED3726 !important;
}

.sku {
    font-size: 16px;
    text-align: center;
    color: #5c5c5c;
}

/* .hostproduct {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #007328;
} */