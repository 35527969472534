.wrapper {
    width: 100%;
    height: 100%;
    background-color: #2b3741;
    /* padding-top: 40px; */
}

.wrapper.notProduct {
    background-color: #a4a4a4;
}

.imgDetail {
    border-radius: 50%;
    max-width: 200px;
    max-height: 200px;
    width: 10vw;
    height: 20vh;
    margin: auto;
    margin-top: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #FFFFFF;
}

.wrapper.notProduct .imgDetail {
    background-color: #c7c7c7;
}

.priceDetail {
    color: #FFFFFF;
    font-size: 3vw;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
}

.wrapper.notProduct .priceDetail {
    color: #8d8d8d;
}

.titleDetail {
    color: #FFFFFF;
    font-size: 1.5vw;
    text-align: center;
    border-top: 10px solid #FFFFFF;
    padding-top: 20px;
}

.wrapper.notProduct .titleDetail {
    color: #8d8d8d;
    border-color: #8d8d8d;
}

.titleData {
    color: #FFFFFF;
    font-size: 1.5vw;
    text-align: center;
    padding: 1vw;
    min-width: 10vw;
    letter-spacing: 2px;
}

.wrapper.notProduct .titleData {
    color: #8d8d8d;
}

.data {
    color: #FFFFFF;
    font-size: 2vw;
    font-weight: bold;
    text-align: center;
}

.wrapper.notProduct .data {
    color: #8d8d8d;
}

.extraDenominationImage {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: auto 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #FFFFFF;
}