.container-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  /* height: 130px; */
}

/* .container-header-logo {
  padding-left: 30px;
} */

/* .header-logo {
  max-width: 15%;
  height: 15%;
} */


.container-info {
  display: flex;
  flex-direction: row;
  font-size: 14px;
}

.container-info-name {
  font-size: 18px;
  font-weight: bold;
}

.container-info-user {
  color: #363636;
}