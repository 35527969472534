.wrapper-table {
    justify-content: center;
    align-items: center;
}

.container-table {
    margin-top: 15px;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    margin-bottom: 15px;
}

.table-th {
    border-bottom: 0px !important;
}