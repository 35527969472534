.list-menu-bar .container-menu-bar {
  padding-left: 20px;
}
.sublist-menu-bar .container-menu-bar {
  padding-left: 40px;
}

.menu-body {
  padding: 0 40px;
  /* height: 80vh; */
  overflow: auto;
  padding-bottom: 40px;
}